import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { Maybe } from '@gql/generated/generated';
import { getRoadmapProgressColor } from '@utils/misc';
import React, { useMemo } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Progress from 'react-native-circular-progress-indicator';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  scoreOutOf100: number;
  children: React.ReactNode;
  title?: Maybe<string>;
  onBack: () => void;
}

const PulseResultWrapper = ({ scoreOutOf100, children, title, onBack }: Props) => {
  const navigate = useNavigate();

  const roadmapProgressColor = useMemo(() => {
    return getRoadmapProgressColor(scoreOutOf100);
  }, [scoreOutOf100]);

  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <TouchableOpacity onPress={onBack}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <View style={styles.headingWrap} dataSet={{ media: ids.headingWrap }}>
          <View style={styles.headingInner} dataSet={{ media: ids.headingInner }}>
            <Progress
              value={scoreOutOf100}
              initialValue={scoreOutOf100}
              radius={30}
              clockwise={false}
              inActiveStrokeOpacity={0.4}
              inActiveStrokeColor={roadmapProgressColor.color}
              progressValueColor={roadmapProgressColor.color}
              title={`${scoreOutOf100}%`}
              titleStyle={styles.progressCircleText}
              activeStrokeColor={roadmapProgressColor.fillColor}
              activeStrokeWidth={10}
              inActiveStrokeWidth={10}
              showProgressValue={false}
            />
            <View style={styles.titleWrap}>
              <Text style={styles.title}>{title || 'Pulse Results'}</Text>
            </View>
          </View>
          <View style={{ justifyContent: 'center' }}>
            <ButtonGeneral label="Priorities" type={ButtonType.user} onPress={() => navigate('/roadmap/priorities')} />
          </View>
        </View>
        {children}
      </ContentBox>
    </Wrapper>
  );
};

export { PulseResultWrapper };
