import { GreenCircleCheckIcon } from '@components/general/icons/event-icons';
import { CancelIcon, CheckIcon, EyeIcon, PlayIcon, PlusIconFill, TrashIcon } from '@components/general/icons/org-icons';
import { ListModal } from '@components/general/modals/list-modal';
import { SearchItem } from '@components/search/search-item';
import { AssignmentsItemDetails } from '@gql/generated/generated';
import { getModuleLessons } from '@utils/misc';
import { PageVariant } from '@utils/models';
import dayjs from 'dayjs';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

export enum ItemType {
  view = 'View',
  markAsDone = 'Mark as done',
  remove = 'Remove',
  deregister = 'Deregister',
  start = 'start',
  moveToOrganization = 'Move to ‘Saved to Organisation’',
  removeFromSavedItems = 'Remove from ‘Saved Items’ list',
}

interface ItemTouchProps {
  title: string;
  icon: React.ReactNode;
  onPress?: () => void;
  isRed?: boolean;
}

const ItemTouch = ({ title, icon, onPress, isRed }: ItemTouchProps) => (
  <TouchableOpacity style={styles.itemWrap} onPress={onPress}>
    {icon}
    <Text style={[styles.itemText, isRed && styles.itemTextRed]}>{title}</Text>
  </TouchableOpacity>
);

interface Props {
  onClose: () => void;
  isVisible: boolean;
  isSuccessVisible?: boolean;
  successInfo?: { title: string; message: string };
  isUndoSuccessVisible?: boolean;
  onUndoSuccess?: () => void;
  value?: AssignmentsItemDetails;
  variant?: PageVariant;
  itemTypes: ItemType[];
  onPressItem: (itemType: ItemType) => void;
}

const OrgModalLayout = ({
  isVisible,
  onClose,
  itemTypes,
  value,
  variant,
  onPressItem,
  onUndoSuccess,
  isSuccessVisible,
  successInfo,
  isUndoSuccessVisible,
}: Props) => {
  const getIcon = (itemType: ItemType) => {
    switch (itemType) {
      case ItemType.view:
        return <EyeIcon />;
      case ItemType.deregister:
        return <CancelIcon />;
      case ItemType.markAsDone:
        return <CheckIcon />;
      case ItemType.moveToOrganization:
        return <PlusIconFill />;
      case ItemType.remove:
        return <TrashIcon />;
      case ItemType.start:
        return <PlayIcon />;
    }
  };

  const renderItem = () => {
    if (value && variant === PageVariant.event) {
      return (
        <SearchItem
          asset={value?.coverImage}
          title={value?.title || ''}
          date={dayjs(value?.date).format('D MMM YY')}
          subtitle={value?.location || ''}
        />
      );
    } else if (value && variant === PageVariant.content) {
      return (
        <SearchItem
          asset={value?.coverImage}
          title={value?.title || ''}
          date={dayjs(value?.date).format('D MMM YY')}
          categories={value?.categories || []}
        />
      );
    } else if (value && variant === PageVariant.content) {
      return (
        <SearchItem
          asset={value?.coverImage}
          title={value?.title || ''}
          date={`${getModuleLessons(value.modules)?.length}`}
          isRating
          subtitle={`${value.averageStars}`}
        />
      );
    } else {
      return (
        <SearchItem
          imageUrl="https://kaboompics.com/cache/8/f/4/4/8/8f448441e8c6ffb8254285163fb061e0d1895733.jpeg"
          title="The art of negotiation"
          date="12 Aug 22"
          categories={['Marketing', 'Sales', 'Operation']}
        />
      );
    }
  };

  return (
    <ListModal isVisible={isVisible} onClose={onClose}>
      {renderItem()}
      {isSuccessVisible ? (
        <View>
          <View style={styles.successTitleWrap}>
            <GreenCircleCheckIcon />
            <Text style={styles.successTitle}>{successInfo?.title || ''}</Text>
          </View>
          <Text style={styles.successDescription}>
            {successInfo?.message || ''}
            {isUndoSuccessVisible ? (
              <Text style={styles.undoText} onPress={onUndoSuccess}>
                Undo
              </Text>
            ) : null}
          </Text>
        </View>
      ) : (
        <View style={styles.itemsWrap}>
          {itemTypes.map((itemType) => (
            <ItemTouch title={itemType} icon={getIcon(itemType)} onPress={() => onPressItem(itemType)} />
          ))}
        </View>
      )}
    </ListModal>
  );
};

export { OrgModalLayout };
