import type { Training } from '../../../gql/generated/generated';

type modules = Training['modules'];
type averageStars = Training['averageStars'];
type comments = Training['comments'];
type shareCount = Training['shareCount'];

export const getLessonCount = (modules: modules) =>
  Object.values(modules || {}).reduce((accumulator, current) => accumulator + Object.values(current?.lessons || {}).length, 0);

export const roundToHours = (seconds: number) => (seconds ? Math.ceil(seconds / 60 / 60) : 1);

type FormatSubtitle = { averageStars: averageStars; comments: comments; shareCount: shareCount };
export const formatSubtitle = ({ averageStars, comments }: FormatSubtitle) => `${averageStars || 0} (${comments ? comments.length : 0} reviews)`;
