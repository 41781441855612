import { useApolloClient } from '@apollo/client';
import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { useUrlQuery } from '@hooks/useUrlQuery';
import { Icon } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { getErrorMessage } from '@utils/misc';
import { canSignUp } from '@utils/platform';
import { AuthError, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { Formik, FormikValues } from 'formik';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';
import * as Yup from 'yup';

import { ids, styles } from './style';
import { auth } from '../../firebase';

export const Login = ({ setIsSignedIn }: any) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const query = useUrlQuery();
  const redirect = query.get('redirect');

  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function waitForFeatureFlags() {
    return new Promise((res) => {
      posthog.onFeatureFlags(() => {
        setTimeout(() => {
          res(null);
        }, 2000);
      });
    });
  }

  const onSubmit = async (values: FormikValues) => {
    setIsLoading(true);
    try {
      posthog.identify(values.email, { email: values.email });
      await waitForFeatureFlags();
      const redirectToHivebrite = posthog.isFeatureEnabled('redirect-to-hivebrite');
      if (redirectToHivebrite) {
        // NB this 'redirect=/' query param check in Login means that users will only be redirected to hivebrite deep-links when NOT authenticated.
        // If the migration to hivebrite takes a longer time then this might need to be addressed.
        const [_, key] = window.location.href.split('redirect=/');
        if (!key) {
          window.location.href = 'https://path-redirect.adam-32b.workers.dev/redirect';
          return;
        }
        window.location.href = `https://path-redirect.adam-32b.workers.dev/redirect?key=${key}`;
        return;
      }

      const credential: UserCredential = await signInWithEmailAndPassword(auth, values.email, values.password);

      if (!credential.user.emailVerified) {
        //Email not verified, signing out and redirecting to verify email page
        await auth.signOut();
        navigate(`/verify-email`, { state: { userId: credential.user.uid, email: credential.user.email } });
      } else {
        setIsSignedIn(true);
        navigate(redirect ? redirect : '/');
      }
    } catch (error) {
      const e = error as AuthError;
      setErrorMsg(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthWrapper onPressBack={() => navigate(-1)}>
      <View>
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          Log in to your account
        </Text>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          Enter your credentials to access your account.
        </Text>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Invalid email').required('This field is required'),
            password: Yup.string().required('This field is required'),
          })}
        >
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <View style={styles.subContainer} dataSet={{ media: ids.subContainer }}>
              <View style={styles.inputWrap}>
                {errorMsg !== '' && (
                  <View style={styles.errorTextContainer}>
                    {Platform.OS === 'web' && <Icon source={AlertMinor} color="critical" />}
                    <Text style={styles.errorText}>{errorMsg}</Text>
                  </View>
                )}
                <Input
                  autoCapitalize="none"
                  keyboardType="email-address"
                  autoComplete="email"
                  textContentType="emailAddress"
                  placeholder="Email Address"
                  value={values.email}
                  onChangeText={handleChange('email')}
                  error={touched.email && errors.email ? (errors.email as string) : undefined}
                />
              </View>
              <Input
                secureTextEntry
                placeholder="Password"
                autoCapitalize="none"
                textContentType="password"
                autoComplete="password"
                value={values.password}
                onChangeText={handleChange('password')}
                error={touched.password && errors.password ? (errors.password as string) : undefined}
              />
              <TouchableOpacity onPress={() => navigate('/forgot-password')} style={styles.forgotPasswordTouch}>
                <Text style={styles.forgotPassword} dataSet={{ media: ids.forgotPassword }}>
                  Forgot Password?
                </Text>
              </TouchableOpacity>
              <ButtonGeneral bold label="Log in" isLoading={isLoading} onPress={handleSubmit} type={ButtonType.user} style={styles.submitButton} />
            </View>
          )}
        </Formik>
        {canSignUp() ? (
          <View style={styles.register} dataSet={{ media: ids.register }}>
            <Text style={styles.noAccount}>Don't have an account?</Text>
            <TouchableOpacity onPress={() => navigate('/signup/1')}>
              <Text style={styles.registerLink}>&nbsp;Register here</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </AuthWrapper>
  );
};
