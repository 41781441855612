import { DotsSaveMenu } from '@components/general/dots-save-menu';
import { ProductReturnMinor } from '@components/general/icons/ProductReturnMinor';
import { TrashIcon } from '@components/general/icons/account-icons/TrashIcon';
import { StarIcon } from '@components/general/icons/feed-icons';
import { CheckIcon } from '@components/general/icons/training-icons';
import { Image } from '@components/general/image-from-storage';
import { PortalAssignModal } from '@components/general/modals/portal-assign-modal/PortalAssignModal';
import { Pill, PillVariant } from '@components/general/pill';
import { TooltipWithChild } from '@components/general/tooltip';
import { selectedUsersAssignModalContext } from '@context/SelectedUsersAssignModalContext';
import { useUserContext } from '@context/UserContext';
import {
  AssignedContentItem,
  AssignedEventItem,
  AssignedItemDetails,
  AssignedTrainingItem,
  ListType,
  SavedContentItem,
  SavedEventItem,
  SavedItemDetails,
  SavedListAction,
  SavedTrainingItem,
  useAssignItemsToUsersMutation,
  useUpdateSavedListMutation,
} from '@gql/generated/generated';
import { useGetFile } from '@hooks/useGetFileUrl';
import { pluralize } from '@utils/misc';
import { PageVariant } from '@utils/models';
import { fromSecondsToHoursOrMins } from '@utils/trainings';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

interface SavedItemInfoProps {
  item: SavedItemDetails | AssignedItemDetails;
  handleDelete: ({
    id,
    type,
    listType,
    __typename,
    createdAt,
    isCompleted,
  }: {
    id: string;
    type: string;
    listType: ListType;
    __typename: string;
    createdAt: number;
    isCompleted: boolean;
  }) => Promise<void>;
  variant: PageVariant.saved | PageVariant.assignment | PageVariant.member;
}

const renderTraining = (props: SavedTrainingItem | AssignedTrainingItem) => (
  <View style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
    <View style={styles.saveItemPersonalInfo}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
          flex: 1,
        }}
      >
        <Text style={styles.textBoldDarkBlue}>{fromSecondsToHoursOrMins(props.durationInSeconds ?? 0)} total</Text>
        <Text style={styles.textBoldDarkBlue}>
          • {props.numLessons} {pluralize(props.numLessons ?? 0, 'lesson')}
        </Text>
      </View>
    </View>
    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
      {props.instructors?.map((instructor) => (
        <View
          key={instructor.id}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image path={instructor.photo?.storagePath} style={styles.saveItemAvatar} />
          <Text style={[styles.textRegularGray]}>{instructor.name}</Text>
        </View>
      ))}
      <Text style={styles.textBoldDark_12} numberOfLines={1}>
        Job role: {props.jobRoles?.map((role) => role.split('-')[0]).join(', ')}
      </Text>
    </View>
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <View style={styles.saveItemStatisticContainer}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <StarIcon />
          <Text style={styles.textRegularGray}>{props.averageStars}</Text>
          <Text style={styles.textRegularGray}>{`(${props.totalStars} ${pluralize(props.totalStars ?? 0, 'review')})`}</Text>
          <Text style={styles.textRegularGray}>{`• ${props.shareCount ?? 0} ${pluralize(props.shareCount ?? 0, 'share')}`}</Text>
        </View>

        <Pill text={props.membershipTiers} variant={PillVariant.Highlight} />
      </View>
      {props.isCompleted ? <Pill text="Complete" variant={PillVariant.SurfaceSuccess} /> : null}
    </View>
  </View>
);

const renderEvent = (props: SavedEventItem | AssignedEventItem) => (
  <View style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
    <View style={styles.saveItemPersonalInfo}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
          flex: 1,
        }}
      >
        <Text style={styles.textBoldDarkBlue}>{dayjs(props.date).format('D MMMM YYYY, h:mma')}</Text>
        <Text style={styles.textRegularGray}>{props.address}</Text>
      </View>
    </View>
    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
      {props.speakers?.map((speaker) => (
        <View
          key={speaker.id}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image path={speaker.photo?.storagePath} style={styles.saveItemAvatar} />
          <Text style={[styles.textRegularGray]}>{speaker.name}</Text>
        </View>
      ))}
      <Text style={styles.textBoldDark_12} numberOfLines={1}>
        Job role: {props.jobRoles?.map((role) => role.split('-')[0]).join(', ')}
      </Text>
    </View>
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <View style={styles.saveItemStatisticContainer}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.textRegularGray}>
            {props.views ?? 0} {pluralize(props.views ?? 0, 'view')}
          </Text>
          <Text style={styles.textRegularGray}>{` • ${props.shareCount ?? 0} ${pluralize(props.shareCount ?? 0, 'share')}`}</Text>
        </View>

        <Pill text={props.membershipTiers} variant={PillVariant.Highlight} />
      </View>
      {props.isCompleted ? <Pill text="Complete" variant={PillVariant.SurfaceSuccess} /> : null}
    </View>
  </View>
);

const renderContent = (props: SavedContentItem | AssignedContentItem) => (
  <View>
    <View style={styles.saveItemPersonalInfo}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 8,
          gap: 8,
          flex: 1,
        }}
      >
        <Text style={[styles.textRegularGray, { minWidth: 'fit-content' }]}>{dayjs(props.publishedAt ?? new Date()).format('D MMMM YY')}</Text>
        <Text style={styles.textBoldDark_12} numberOfLines={1}>
          Job role: {props.jobRoles?.map((role) => role.split('-')[0]).join(', ')}
        </Text>
      </View>
    </View>
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <View style={styles.saveItemStatisticContainer}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.textRegularGray}>
            {props.views ?? 0} {pluralize(props.views ?? 0, 'view')}
          </Text>
          <Text style={styles.textRegularGray}>{` • ${props.commentCount ?? 0} ${pluralize(props.commentCount ?? 0, 'comment')}`}</Text>
          <Text style={styles.textRegularGray}>{` • ${props.shareCount ?? 0} ${pluralize(props.shareCount ?? 0, 'share')}`}</Text>
        </View>

        <Pill text={props.membershipTiers} variant={PillVariant.Highlight} />
      </View>
      {props.isCompleted ? <Pill text="Complete" variant={PillVariant.SurfaceSuccess} /> : null}
    </View>
  </View>
);

const SavedItemInfo = ({ item, handleDelete, variant }: SavedItemInfoProps) => {
  const { categories, id, __typename, coverImage, isCompleted, shareCount, subtitle, title, type, savedAt } = item;

  const { currentUser } = useUserContext();

  const listType = item.__typename?.includes('Saved') ? ListType.SAVED_ITEMS : ListType.ASSIGNED_ITEMS;

  const { selectedUsers, setSelectedUsers } = useContext(selectedUsersAssignModalContext);
  const [modalVisible, setModalVisible] = useState(false);

  const [updateSavedList] = useUpdateSavedListMutation();

  const navigate = useNavigate();

  const findCardPath = (id: string, type?: string | null) => {
    if (type === 'Training') {
      return navigate(`/training/${id}`);
    }
    if (type === 'Content') {
      return navigate(`/content/${id}`);
    }
    if (type === 'Event') {
      return navigate(`/events/${id}`);
    }
  };

  const [updateUsersAssignments, { loading: loadingUpdatingAssignments }] = useAssignItemsToUsersMutation();

  const updateUsersAssignmentsHandler = async () => {
    if (!type) return;
    await updateUsersAssignments({
      variables: {
        input: {
          action: SavedListAction.ADD_TO_USER,
          item: {
            id,
            type,
          },
          userIds: selectedUsers.map((user) => user.id),
        },
      },
    });
    !loadingUpdatingAssignments && setModalVisible(false);
    setSelectedUsers([]);
  };

  const employerItems = currentUser?.role?.isEmployer
    ? [
        {
          content: 'Assign',
          prefix: <ProductReturnMinor />,
          onAction: () => setModalVisible(true),
        },
      ]
    : [];

  const markAsDone =
    !item.isCompleted && variant !== PageVariant.member
      ? [
          {
            content: 'Mark as done',
            prefix: <CheckIcon />,
            onAction: async () => {
              if (!item.type) return;
              await updateSavedList({
                variables: {
                  input: {
                    action: SavedListAction.COMPLETE,
                    item: {
                      id: item.id,
                      type: item.type,
                    },
                    listType,
                  },
                },
                update: (store) => {
                  store.modify({
                    id: `${item.__typename}:${item?.id}`,
                    fields: {
                      isCompleted() {
                        return true;
                      },
                    },
                  });
                },
              });
            },
          },
        ]
      : [];

  const webMenuItem = [
    ...markAsDone,
    ...employerItems,
    {
      helpText: <Text style={{ color: 'red' }}>Remove</Text>,
      prefix: <TrashIcon />,
      onAction: async () => {
        if (!item.type || !__typename) return;
        await handleDelete({
          id,
          type: item.type,
          listType,
          __typename,
          createdAt: savedAt ?? 0,
          isCompleted: !!isCompleted,
        });
      },
    },
  ];

  const { fileUrl } = useGetFile(coverImage?.storagePath);

  const modifiedCategories = useMemo(() => {
    if (!categories?.length) return [];
    const sliceArray = categories?.map((cat) => {
      cat = cat.slice(0, cat.indexOf(':'));
      return cat;
    });
    const noDuplicates = new Set([...sliceArray]);
    return [...noDuplicates];
  }, [categories]);

  const cardContent = useMemo(() => {
    switch (__typename) {
      case 'AssignedContentItem':
      case 'SavedContentItem': {
        return renderContent(item as SavedContentItem | AssignedContentItem);
      }
      case 'AssignedEventItem':
      case 'SavedEventItem': {
        return renderEvent(item as SavedEventItem | AssignedEventItem);
      }
      case 'AssignedTrainingItem':
      case 'SavedTrainingItem': {
        return renderTraining(item as SavedTrainingItem | AssignedTrainingItem);
      }
    }
  }, [__typename, item]);

  return (
    <>
      <PortalAssignModal
        title={title}
        subtitle={subtitle}
        image={fileUrl}
        categories={categories ?? []}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        updateUsersAssignmentsHandler={updateUsersAssignmentsHandler}
        contentType={type}
        additionalDetail={[type ?? 'Content', `${shareCount} ${pluralize(shareCount ?? 0, 'share')}`]}
      />
      <TouchableOpacity style={styles.saveItemWrap} onPress={() => findCardPath(id, type)}>
        <View style={styles.saveItemBadgeImageWrapper}>
          <Image path={item.coverImage?.storagePath} style={styles.saveItemImage} />
          <View style={styles.saveItemBadges}>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              {modifiedCategories?.slice(0, 1).map((category) => (
                <Pill key={category} text={category} withMarginRight />
              ))}
              {modifiedCategories?.length && modifiedCategories.length > 1 ? (
                <TooltipWithChild toolTipText={modifiedCategories?.slice(1).join(', ')} width={100}>
                  <Pill text={`+${modifiedCategories.length - 1}`} />
                </TooltipWithChild>
              ) : null}
            </View>
          </View>
        </View>

        <View style={styles.saveItemInfoContainer}>
          <View>
            <View
              style={{
                marginBottom: 4,
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text style={styles.textBoldDark} numberOfLines={1}>
                {title}
              </Text>
              <TouchableOpacity activeOpacity={1} style={{ position: 'absolute', right: 0 }}>
                <DotsSaveMenu actionItems={webMenuItem} />
              </TouchableOpacity>
            </View>
            <Text style={styles.textRegularGray} numberOfLines={2}>
              {subtitle}
            </Text>
          </View>

          {cardContent}
        </View>
      </TouchableOpacity>
    </>
  );
};

export { SavedItemInfo };
