import { AvatarWithName } from '@components/back-office/editor/avatar-with-name';
import { DeleteConfirmationModal } from '@components/back-office/editor/delete-confirmation-modal';
import { StatusTag, TagStatus } from '@components/back-office/editor/status-tag';
import { GridPage } from '@components/back-office/grid/GridPage';
import { useQueryFilters } from '@components/back-office/grid/useGridFilters';
import { GenericTableRowItem } from '@components/back-office/tables/generic-table';
import { useToast } from '@context/ToastContext';
import { useAdminDeleteUserMutation, useAdminGetUsersQuery } from '@gql/generated/generated';
import { Modal } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { isWithinLast30Days } from '@utils/misc';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';

const activeFilters = ['search', 'accountStatus', 'role', 'userTypes', 'memberSince'];

export const UsersBackOffice = () => {
  const [adminDeleteUser, { loading: adminDeleteUserLoading }] = useAdminDeleteUserMutation();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { page, filters } = useQueryFilters(activeFilters);
  const [active, setActive] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const {
    data: users,
    loading,
    refetch,
  } = useAdminGetUsersQuery({
    variables: {
      //@ts-ignore
      filters,
      page,
      limit: 10,
    },
  });

  const userData = users?.adminGetUsers;
  const total = users?.adminGetUsers.total;
  const hasNextPage = users?.adminGetUsers.hasNextPage;

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Name' },
    { title: 'Type' },
    { title: 'Organization' },
    { title: 'Status' },
    { title: 'Last Logged in' },
    { title: 'Member Type' },
    { title: 'Member Since' },
    { title: '' },
  ];

  const rows: GenericTableRowItem[] =
    userData?.users?.map((user, i) => {
      const firstName = user?.accountInfo?.firstName ?? '';
      const lastName = user?.accountInfo?.lastName ?? '';
      const name = `${firstName} ${lastName}`;
      const organization = user?.accountInfo?.companyInfo.name;
      const userType = user?.subscriptionDetails?.subscriptionPlanAsText || 'Free';

      const getAccountStatus = (status?: string | null) => {
        if (status !== 'Active') {
          return {
            status: TagStatus.Default,
            text: status,
          };
        }

        return user?.lastLoggedInDate && isWithinLast30Days(user.lastLoggedInDate)
          ? { status: TagStatus.Active, text: 'Active' }
          : { status: TagStatus.Inactive, text: 'Inactive' };
      };

      const getUserType = (user: any) => {
        if (user.role.isAdmin) return 'Admin';
        if (user.role.isEmployer) return 'Employer';
        if (user.role.isUser) return 'Employee';
        return 'unknown';
      };

      return {
        id: user?.id ?? '',
        title: <AvatarWithName name={name} imageSrc={user?.profilePhotoUrl} i={i} />,
        isAdmin: <View>{getUserType(user)}</View>,
        organization,
        status: (
          <StatusTag status={getAccountStatus(user?.status?.accountStatus).status}>{getAccountStatus(user?.status?.accountStatus).text}</StatusTag>
        ),
        lastLogged: dayjs(user?.lastLoggedInDate).format('DD/MM/YYYY'),
        userType,
        memberSince: dayjs(user?.registrationDate).format('DD/MM/YYYY'),
      };
    }) ?? [];

  async function deleteUser() {
    if (!selectedItemId || adminDeleteUserLoading) {
      return;
    }
    try {
      await adminDeleteUser({ variables: { id: selectedItemId } });
      addToast('success', 'User successfully deleted');
      setTimeout(async () => {
        await refetch();
      }, 2000);
    } catch (err) {
      console.error('ERROR - deleteUser():', err);
    } finally {
      setActive(false);
      setSelectedItemId(null);
    }
  }

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case 'View': {
        navigate(`/user-editor/${itemId}`);
        break;
      }
      case 'Edit': {
        navigate(`/user-editor/${itemId}`);
        break;
      }
      case 'Delete':
        // Show delete modal
        setSelectedItemId(itemId);
        setActive(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this user?"
        onClose={() => {
          setActive(false);
          setSelectedItemId(null);
        }}
        onDeleteConfirm={deleteUser}
        visible={active}
        loading={loading}
      />
      <GridPage
        isUsersTable
        title="Users"
        menuItems={[
          { id: 'View', content: 'View' },
          { id: 'Edit', content: 'Edit' },
          { id: 'Delete', content: 'Delete' },
        ]}
        newLink={{
          url: '/user-editor',
          title: 'Add New User',
          label: 'Add User',
        }}
        activeFilters={activeFilters}
        rows={rows || []}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
