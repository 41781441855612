import { LinkIconDark } from '@components/general/icons';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '@components/general/icons/social-icons';
import { APP_URL } from '@env';
import { Lesson, Module } from '@gql/generated/generated';
import { firebaseErrorCodes } from '@utils/constants';
import * as FileSystem from 'expo-file-system';
import { AuthError } from 'firebase/auth';
import { getDownloadURL, ref } from 'firebase/storage';
import { customAlphabet } from 'nanoid';
import { ImageSourcePropType } from 'react-native';

import { parseFirebaseAuthError } from './auth';
import BlueAvatar from '../../assets/img/avatars/BlueAvatar.png';
import DarkBlueAvatar from '../../assets/img/avatars/DarkBlueAvatar.png';
import GreenAvatar from '../../assets/img/avatars/GreenAvatar.png';
import LightGreenAvatar from '../../assets/img/avatars/LightGreenAvatar.png';
import PinkAvatar from '../../assets/img/avatars/PinkAvatar.png';
import PurpleAvatar from '../../assets/img/avatars/PurpleAvatar.png';
import YellowAvatar from '../../assets/img/avatars/YellowAvatar.png';
import { storage } from '../firebase';

export const MOBILE_SCREEN_WIDTH = 768;

const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const formatFileTitle = (name: string, size?: number | null) => {
  const filenameComponents = name.split('.');
  const extension = filenameComponents.pop();
  const filenameWithoutExtension = filenameComponents.join('.');
  const validExtension = extension !== name;

  if (validExtension) {
    return `${filenameWithoutExtension} | ${extension?.toUpperCase()} ${size && `• ${formatBytes(size)}`}`;
  }

  return `${name} ${size && `• ${formatBytes(size)}`}`;
};

const customFirestoreId = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 20);

const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

const camelizeKebab = (str: string) => {
  return str
    .toLowerCase()
    .replace(/-./g, (x) => x[1]?.toUpperCase() || '')
    .trim();
};

const capitalizeFirstLetter = (string?: string | null) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getInitialsFromName = (name: string) => {
  const arr = name.split(' ');
  if (arr.length < 2) {
    return name.charAt(0);
  }
  const firstInitial = arr[0]?.charAt(0) || '';
  const lastInitial = arr[arr.length - 1]?.charAt(0) || '';
  return firstInitial.toUpperCase() + lastInitial.toUpperCase();
};

const truncateString = (str: string, length: number) => {
  return `${str.slice(0, length)}${str.length > length ? '...' : ''}`;
};

const joinItemsWithCommasAndAmpersand = (items: string[]) => {
  if (items.length === 0) {
    return '';
  } else if (items.length === 1) {
    return items[0];
  } else {
    let result = '';
    for (let i = 0; i < items.length; i++) {
      if (i === items.length - 1) {
        result += '& ' + items[i];
      } else if (i === items.length - 2) {
        result += items[i] + ' ';
      } else {
        result += items[i] + ', ';
      }
    }
    return result;
  }
};

const mediaQuery = {
  forMobile: '@media (max-width: 768px)',
  forTabletDown: '@media (max-width: 1024px)',
  forTabletOnly: '@media (min-width: 768px) and (max-width: 1024px)',
  // based on the 1204 max-width of the navbar + default left and right padding
  forDesktopSm: '@media (max-width: 1236px)',
  notForMobile: '@media (min-width: 769px)',
};

const getModuleLessons = (modules?: Module[] | null) => modules?.reduce((agg: Lesson[], module: Module) => [...agg, ...module?.lessons], []);

const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const downloadFile = async (url: string) => {
  const filename = url.substring(url.lastIndexOf('/') + 1);
  const download = FileSystem.createDownloadResumable(url, FileSystem.documentDirectory + filename);
  await download.downloadAsync();
};

const getShareURLs = (path: string, isBaseURLAppended?: boolean) => ({
  facebook: `https://www.facebook.com/dialog/share?app_id=195007143606310&display=popup&href=${isBaseURLAppended ? APP_URL : ''}${path}`,
  twitter: `https://twitter.com/intent/tweet?text=${isBaseURLAppended ? APP_URL : ''}${path}`,
  linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${isBaseURLAppended ? APP_URL : ''}${path}`,
});

const shareOptions = (pathname: string) => [
  {
    title: 'Share to Facebook',
    icon: <FacebookIcon />,
    link: getShareURLs(pathname).facebook,
  },
  {
    title: 'Share to Twitter',
    icon: <TwitterIcon />,
    link: getShareURLs(pathname).twitter,
  },
  {
    title: 'Share to LinkedIn',
    icon: <LinkedInIcon />,
    link: getShareURLs(pathname).linkedin,
  },
  {
    title: 'Copy link',
    icon: <LinkIconDark />,
  },
];

const saveOptions = [{ title: 'Save for me' }, { title: 'Save for my organisation' }];

const navbarRoutes = ['/', '/content', '/training', '/events', '/account'];

const pastTensify = (word?: string | null) => {
  if (!word) return '';
  if (word.endsWith('y')) {
    return word.slice(0, -1) + 'ied';
  } else if (word.endsWith('e')) {
    return word + 'd';
  } else {
    return word + 'ed';
  }
};

const getTooltipObjectFromDbCategories = (categories?: string[] | null) => {
  if (!categories) return {};
  return categories.reduce(
    (acc, el) => {
      const { hoverText, text } = acc;
      const arr = el.split(': ');
      const cat = arr[0];
      const newText = !text ? cat : text.includes(cat) ? text : text + ', ' + cat;
      const newHoverText = !hoverText ? el : hoverText + ', ' + el;
      const result = {
        text: newText,
        hoverText: newHoverText,
        type: 'toolTip',
      };

      return result;
    },
    { text: '', hoverText: '', type: 'toolTip' }
  );
};

const getErrorMessage = (error: AuthError | Error) => {
  if (!error.message) {
    return error.message || 'Encountered an error';
  }

  if (!('code' in error)) {
    return error.message;
  }

  if (!firebaseErrorCodes[error.code]) {
    return error.message;
  }

  if (error.code === 'auth/internal-error' && error.name === 'FirebaseError') {
    return parseFirebaseAuthError(error);
  }

  return firebaseErrorCodes[error.code];
};

const removeNestedKey = (obj: any, key: string): any => {
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNestedKey(item, key));
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: any, prop) => {
      if (prop === key) {
        return acc;
      }
      acc[prop] = removeNestedKey(obj[prop], key);
      return acc;
    }, {});
  }
  return obj;
};

const getUniqueCategories = (categories?: string[] | null) => {
  if (!categories) return [];
  return categories.reduce((acc: string[], el) => {
    const cat = el.split(':')[0];

    return acc.includes(cat) ? acc : [...acc, cat];
  }, []);
};

const getRoadmapProgressColor = (percent: number) => {
  if (percent < 35) {
    return { color: '#FFA7A3', fillColor: '#FD5749' };
  } else if (percent < 85) {
    return { color: '#FFD79D', fillColor: '#FFC96B' };
  } else if (percent <= 100) {
    return { color: 'rgba(138, 203, 200, 0.58)', fillColor: '#33A5A1' };
  } else {
    return { color: '#2C6ECB', fillColor: '#2C6ECB' };
  }
};

const getRoadmapColorFromScoreColor = (scoreColor: string) => {
  if (scoreColor === 'red') {
    return { color: '#FFA7A3', fillColor: '#FD5749' };
  } else if (scoreColor === 'amber') {
    return { color: '#FFD79D', fillColor: '#FFC96B' };
  } else if (scoreColor === 'green') {
    return { color: 'rgba(138, 203, 200, 0.58)', fillColor: '#33A5A1' };
  } else {
    return { color: '#2C6ECB', fillColor: '#2C6ECB' };
  }
};

const isWithinLast30Days = (dateNum: number | string): boolean => {
  const daysInMs = 30 * 24 * 60 * 60 * 1000;
  const currentDate = new Date();
  const givenDate = new Date(Number(dateNum)); // Convert dateNum to a number explicitly
  return currentDate.getTime() - givenDate.getTime() <= daysInMs;
};

const convertSecondsToHours = (seconds: number) => {
  const minutes = Math.ceil(seconds / 60);
  return Math.round(minutes / 60).toFixed(0);
};

const getCoverImageForContent = async (id?: string | null, coverImageFileName?: string | null) => {
  if (!coverImageFileName || !id) return '';
  try {
    const imageUrl = await getDownloadURL(ref(storage, `content/${id}/${coverImageFileName}`));
    return imageUrl;
  } catch (error) {
    console.log({ error });
  }
};

const getDefaultAvatar = (defaultAvatarIndex: number): ImageSourcePropType => {
  switch (defaultAvatarIndex) {
    case 0:
      return PinkAvatar;
    case 1:
      return BlueAvatar;
    case 2:
      return DarkBlueAvatar;
    case 3:
      return GreenAvatar;
    case 4:
      return LightGreenAvatar;
    case 5:
      return PurpleAvatar;
    case 6:
      return YellowAvatar;
    default:
      return BlueAvatar;
  }
};

const pluralize = (value: number, singular: string, plural?: string) => {
  if (value === 1) {
    return singular;
  } else {
    return plural || singular + 's';
  }
};

const dateFromMillis = (num?: number | null) => {
  if (!num) return '';
  const date = new Date(num);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const calculateNewRoundedAverage = ({ x, oldAverage, additionalScore }: { x: number; oldAverage: number; additionalScore: number }) =>
  Math.round(((additionalScore - oldAverage) / x + oldAverage) * 10) / 10;

const quarterOfYear = (date: Date) => Math.ceil((date.getMonth() + 1) / 3);

export {
  camelize,
  camelizeKebab,
  pluralize,
  getInitialsFromName,
  truncateString,
  joinItemsWithCommasAndAmpersand,
  mediaQuery,
  getDefaultAvatar,
  getModuleLessons,
  getInitials,
  downloadFile,
  getShareURLs,
  pastTensify,
  navbarRoutes,
  shareOptions,
  saveOptions,
  getTooltipObjectFromDbCategories,
  getErrorMessage,
  removeNestedKey,
  getUniqueCategories,
  getRoadmapProgressColor,
  isWithinLast30Days,
  convertSecondsToHours,
  getRoadmapColorFromScoreColor,
  getCoverImageForContent,
  capitalizeFirstLetter,
  dateFromMillis,
  calculateNewRoundedAverage,
  formatBytes,
  customFirestoreId,
  formatFileTitle,
  quarterOfYear,
};
