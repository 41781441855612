import { Modal } from 'react-native';

import { ModalContainer } from '../../../org/saved/modals/ModalContainer';
import { GenericModalLayout } from '../../../org/saved/modals/generic-modal-layout/GenericModalLayout';

interface DeleteConfirmationModalProps {
  title: string;
  onClose: () => void;
  onDeleteConfirm: () => void;
  visible: boolean;
  loading?: boolean;
}

const DeleteConfirmationModal = ({ title, onClose, onDeleteConfirm, visible, loading = false }: DeleteConfirmationModalProps) => (
  <Modal visible={visible} transparent>
    <ModalContainer>
      <GenericModalLayout
        title={title}
        loading={loading}
        closeModal={onClose}
        hasButtonLayout
        buttonName="Delete"
        onActionButtonPress={onDeleteConfirm}
        withCancel
      >
        {null}
      </GenericModalLayout>
    </ModalContainer>
  </Modal>
);

export default DeleteConfirmationModal;
