import Svg, { Path } from 'react-native-svg';

function NotFoundSVG() {
  return (
    <Svg width={459} height={296} viewBox="0 0 460 296" fill="none">
      <Path d="M278.716 25.458l-28.838-3.241-29.942 266.427 28.838 3.241 29.942-266.427z" fill="#0A173A" />
      <Path d="M338.5 23h-60.499v268.104H338.5V23zM128.889.004l-13.397 6.86L235.06 240.387l13.397-6.86L128.889.004z" fill="#0A173A" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.897 78.287c1.789-3.765 2.549-7.994 1.124-12.125-4.108-11.9-20.284-4.715-21.926 2.285-1.643 7 2.917 22.424 7.457 22.382 1.811-.016 5.132-2.05 8.21-5.244l2.071 16.914 13.608-3.64-10.544-20.572z"
        fill="#D4A181"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.963 78.694c2.557 2.786 5.546 4.6 7.879 3.976 4.43-1.185 5.992-7.947 4.283-14.31-.06-.224-.124-.445-.192-.663 5.55 1.96 10.862 7.328 17.063 5.67 8.436-2.257 7.223-20.158-4.564-17.005-8.016 2.144-11.646 4.367-14.581 7.003-1.177-1.709-2.625-3.023-4.172-3.797-.391-.258-.939-.458-1.614-.6a6.519 6.519 0 00-1.256-.18c-2.252-.205-5.362.059-8.709.83-.625.144-1.235.3-1.826.467a5.877 5.877 0 00-7.189-4.133 5.864 5.864 0 00-4.151 7.185 5.877 5.877 0 006.884 4.225c1.852.07 4.154-.13 6.634-.62.002.953.145 1.998.451 3.139a17.862 17.862 0 001.165 3.078c.36-.77.957-1.368 1.675-1.56 2.006-.537 3.846.739 4.316 2.49.596 2.223-.256 4.175-2.096 4.805z"
        fill="#191847"
      />
      <Path fillRule="evenodd" clipRule="evenodd" d="M407.808 150.711l-21.514 74.048-10.119 55.597h-10.156l10.138-129.645h31.651z" fill="#BA8B72" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.476 150.711c-1.501 38.468-.909 59.449-.573 62.944.336 3.495 2.244 26.902 15.241 67.874H421.58c-17.213-39.306-23.221-62.713-25.196-67.874-1.974-5.161-7.804-26.143-16.317-62.944h37.409z"
        fill="#D4A181"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M408.27 150.711c-5.597 25.48-15.275 66.153-29.037 122.019h-14.855c-1.097-57.341 3.57-95.081 11.653-122.019h32.239z"
        fill="#2C6ECB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.551 150.711c-1.47 38.468 2.34 76.366 14.143 122.618h-15.85c-17.244-38.72-29.604-76.431-38.051-122.618h39.758z"
        fill="#4A81FF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.65 289.155l-.583-9.386c-3.413.997-7.687.606-12.823-1.173-5.523 3.907-12.475 6.575-20.857 8.004a1.174 1.174 0 00-.932 1.476l.806 2.839h33.223l1.166-1.76zM378.442 289.155l-.583-9.386c-3.412.997-7.687.606-12.823-1.173-5.522 3.907-12.474 6.575-20.856 8.004a1.172 1.172 0 00-.933 1.476l.806 2.839h33.223l1.166-1.76z"
        fill="#191847"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.782 117.736l-22.015-7.452c-4.037-3.374-.838-5.797-4.465-7.418-.802-.359-.802 2.402-.802 2.402v8.231c-.483 1.096 3.5 2.001 4.33 2.714l16.943 10.777 6.009-9.254zm-29.802 14.801l-9.672-3.288c-2.068-4.486-3.965-6.592-5.691-6.319-1.431-.382 1.921 5.157-.676 5.082-2.596-.074-9.515-3.542-10.406-2.434-1.318 1.639.813 5.587 2.819 7.384 3.75 3.358 6.52 4.245 13.093 4.888 3.436.336 7.301 1.542 11.593 3.617l-1.06-8.93z"
        fill="#D4A181"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.128 113.563c-7.044 1.164-16.682 1.33-27.807-3.255l-2.383 9.447c8.11 5.629 18.806 9.544 27.248 7.813 6.652-1.365 8.642-10.048 2.942-14.005z"
        fill="#2026A2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M368.139 129.266c-7.136 3.173-20.04 3.182-38.713.028-2.767 9.775-3.647 16.552-2.64 20.33 16.462 5.214 29.999 6.448 40.683 4.806.776 6.369 2.606 12.238 6.157 17.269 7.301 10.344 36.539-5.571 47.354-5.354 2.793-22.389-11.387-28.793-13.798-42.38-2.64-14.88-18.646-35.681-20.406-35.371l-16.046 2.823c1.123 11.521-1.12 24.831-2.591 37.849z"
        fill="#1F28CF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.479 54.297c4.006 6.8 11.22 22.919 9.209 26.274l-17.083 4.57-.03-.09c-6.52.993-12.884-3.054-14.633-9.568l-3.228-12.028c-1.877-6.99 2.273-14.173 9.268-16.044l1.178-.315c6.274-1.678 12.706 1.487 15.319 7.2z"
        fill="#191847"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.98 71.479c-3.562-2.43-6.437-5.807-7.293-10.222-2.468-12.723 15.814-14.668 20.919-9.279 5.105 5.39 8.991 21.483 4.883 23.79-1.638.919-5.681.82-10.107-.437l6.899 16.138-14.135 3.78-1.166-23.77z"
        fill="#B28B67"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.233 68.35c2.511 6.756 4.375 14.74 2.938 17.137l-17.084 4.57C73.738 73.932 84.64 77.814 80.5 62.392c.425-.708.82-1.402 1.229-2.02l-.141-.448c1.348-6.79 4.296-10.792 8.844-12.009 6.822-1.825 9.819.743 12.369 2.922-.121 5.026-5.255 7.14-9.203 11.308a3.361 3.361 0 00-2.234-.17c-1.79.478-2.855 2.302-2.38 4.075.477 1.772 2.313 2.82 4.102 2.342.05-.013.099-.027.147-.043z"
        fill="#191847"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.69 203.977c14.634 4.073 27.259 6.109 37.877 6.109 10.618 0 20.48-3.136 29.587-9.408-14.321-4.18-26.115-6.27-35.382-6.27s-19.961 3.19-32.083 9.569z"
        fill="#1F28CF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.236 215.956a3.344 3.344 0 01-.355-.689c-1.956-5.147-33.288-60.674-36.41-70.826h35.276c2.373 7.719 13.055 56.156 14.558 65.82 4.743 15.244 14.509 64.613 15.756 68.034 1.311 3.598-6.068 7.346-8.035 2.922-3.128-7.038-10.584-26.512-13.903-38.403-3.112-11.148-5.646-21.177-6.887-26.858z"
        fill="#997659"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.375 222.735c-14.324 3.603-66.72 10.115-70.316 11.136-3.699 1.051-6.942-6.535-2.37-8.178 7.275-2.614 27.32-8.656 39.473-11.121 9.776-1.983 18.696-3.601 24.685-4.548-.508-16.738-2.827-56.907-1.307-65.583h31.212c-1.457 8.32-13.78 69.023-16.07 75.099-.727 2.169-2.896 3.128-5.307 3.195z"
        fill="#B28B67"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.972 223.619c-1.913-.355-3.314-.455-4.204-.298-1.083.19-2.631.649-4.646 1.375l5.98 33.847c3.065.367 4.546-.417 4.443-2.353a190.704 190.704 0 01-.205-4.833l4.239-16.967a.61.61 0 00-.444-.739l-.017-.004-2.633-.579c-1.13-1.61-1.79-2.964-1.984-4.061-.158-.891-.015-2.185.428-3.882a1.219 1.219 0 00-.957-1.506zM144.787 280.704c-.682 1.82-1.023 3.182-1.023 4.085 0 1.099.183 2.702.549 4.81h34.407c.894-2.952.378-4.546-1.548-4.782a192.088 192.088 0 01-4.801-.637l-15.992-7.121a.611.611 0 00-.806.308l-.006.016-1.028 2.491c-1.784.831-3.233 1.246-4.348 1.246-.906 0-2.157-.365-3.753-1.096a1.22 1.22 0 00-1.617.6l-.034.08z"
        fill="#E4E4E4"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.641 144.442c13.754-3.915 26.768-3.915 39.042 0 4.27 19.385 14.646 27.079 16.47 56.799-20.741 10.254-46.362-7.313-67.712 2.687-6.1-13.987 2.44-47.671 12.2-59.486z"
        fill="#2B44FF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.186 104.294l23.844-19.022c2.744-4.704 5.598-8.485 8.563-11.342.953-.608 2.706-1.183 1.548 2.081-1.157 3.264-2.227 6.748-1.209 7.511 1.019.764 2.929-.89 4.22.45.86.893-2.507 3.135-10.102 6.726l-17.713 20.458-9.151-6.862zm34.347 3.833l8.275-6.614c.426-5.088 1.53-7.804 3.312-8.15 1.26-.876-.045 5.688 2.463 4.697 2.509-.99 8.031-6.806 9.294-6.045 1.869 1.126 1.19 5.713-.13 8.168-2.468 4.587-4.857 6.428-11.045 9.377-3.236 1.542-6.58 4.08-10.033 7.613l-2.136-9.046z"
        fill="#997659"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.581 103.555c7.29-1.362 16.758-4.61 25.991-12.996l5.679 8.33c-5.918 8.333-14.97 15.917-23.826 17.222-6.979 1.028-12.004-6.698-7.844-12.556z"
        fill="#2026A2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.975 116.332c8.092.558 20.692-3.997 37.801-13.665 6.171 8.514 9.436 14.784 9.795 18.809-14.218 10.885-26.995 16.871-38.007 19.056 1.503 6.459 1.801 12.805.121 18.947-3.455 12.627-37.647 7.514-48.127 11.549-10.676-20.754.892-31.988-1.579-46.034-2.706-15.383 5.533-41.244 7.36-41.565l16.667-2.933c2.995 11.584 9.911 23.715 15.969 35.836z"
        fill="#1F28CF"
      />
      <Path d="M128.889.004l-13.397 6.86 43.139 84.254 13.397-6.86L128.889.004z" fill="#0A173A" />
    </Svg>
  );
}

export default NotFoundSVG;
