import 'setimmediate';
import enPolarisTranslations from '@shopify/polaris/locales/en.json';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AppRegistry } from 'react-native';
import { BrowserRouter } from 'react-router-dom';

import { Index } from './src';

import '@shopify/polaris/build/esm/styles.css';
import { UserContextProvider } from '@context/UserContext';
import { ApolloProvider } from '@apollo/client';

import { useCreateApolloClient } from './src/apolloClient';

import { AppProvider } from '@shopify/polaris';
import { POSTHOG_HOST, POSTHOG_PROXY_HOST, POSTHOG_API_KEY } from '@env';

export function App() {
  const apolloClient = useCreateApolloClient();

  // Check if the user is visiting from default domains by firebase
  useEffect(() => {
    const { host } = window.location;

    const hostSplit = host.split('.');
    const domain = hostSplit.at(-2);
    const app = hostSplit.at(-3);

    const isFirestoreDomain = domain === 'firebaseapp' || domain === 'web';
    if (!isFirestoreDomain) return;
    if (app === 'trndev') {
      window.location.href = `https://trnworld.com${window.location.pathname}`;
      return;
    }
    if (app === 'admin-trndev') {
      window.location.href = `https://admin.trnworld.com${window.location.pathname}`;
      return;
    }
    if (app === 'trnweb') {
      window.location.href = `https://staging.trnworld.com${window.location.pathname}`;
      return;
    }
    if (app === 'trnadminweb') {
      window.location.href = `https://stagingadmin.trnworld.com${window.location.pathname}`;
    }
  }, []);

  return (
    <PostHogProvider
      apiKey={POSTHOG_API_KEY}
      options={{
        api_host: POSTHOG_PROXY_HOST,
        ui_host: POSTHOG_HOST,
      }}
    >
      <AppProvider i18n={enPolarisTranslations}>
        <Helmet>
          <style>{'body { background-color: rgb(237, 237, 237); }'}</style>
        </Helmet>

        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <UserContextProvider>
              <Index />
            </UserContextProvider>
          </BrowserRouter>
        </ApolloProvider>
      </AppProvider>
    </PostHogProvider>
  );
}

AppRegistry.registerComponent('trn_frontend', () => App);
