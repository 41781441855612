import { ButtonGeneral } from '@components/general/button-general';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { Frame } from '@shopify/polaris';
import { View, Text } from 'react-native';
import { useNavigate } from 'react-router-native';

import NotFoundSVG from '../../icons/NotFoundSVG';

export function NotFound() {
  const navigate = useNavigate();
  const goHome = () => navigate('/');
  return (
    <Frame>
      <Wrapper>
        <ContentBox style={{ padding: 16, marginTop: 16 }}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 50,
              paddingVertical: 16,
            }}
          >
            <NotFoundSVG />
            <View
              style={{
                display: 'flex',
                gap: 19,
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 42,
                  lineHeight: 44,
                  fontFamily: 'OpenSans_600SemiBold',
                }}
              >
                Oops!
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  fontFamily: 'OpenSans_400Regular',
                  color: '#6D7175',
                }}
              >
                We couldn’t find the page you are looking for!
              </Text>
              <ButtonGeneral label="Go home" onPress={goHome} style={{ borderRadius: 35 }} />
            </View>
          </View>
        </ContentBox>
      </Wrapper>
    </Frame>
  );
}
