import { WebIcon } from '@components/general/icons/social-icons';
import { Toast } from '@components/general/toast';
import { ContentType } from '@gql/generated/generated';
import { getShareURLs, pluralize } from '@utils/misc';
import React, { useState } from 'react';
import { Image, Platform, Text, View, TouchableOpacity } from 'react-native';
import { useLocation } from 'react-router-native';

import { ids, styles } from './style';
import Star from '../../../../assets/img/star.png';
import { Col } from '../col';
import { SocialButtons } from '../icons';
import { Pill, PillVariant } from '../pill';
import { Row } from '../row';
import { Tooltip, TooltipWithChild } from '../tooltip';

export const CategoriesJobRoleTags = ({ categories, jobRoles }: { categories?: string[] | null; jobRoles?: string[] | null }) => {
  return (
    <View>
      {categories && categories.length > 0 ? (
        <Row style={styles.categoryContainer}>
          <Text style={[styles.subHeading, { marginBottom: 6 }]}>Categories:</Text>

          <View style={{ ...styles.pillContainer }}>
            {categories.slice(0, 2).map((category: string) => {
              return (
                <View style={{ width: 'fit-content' }} key={category}>
                  <Pill key={category} variant={PillVariant.Light} withMarginRight text={category.split(':')?.[1] ?? category} />
                </View>
              );
            })}
            {categories.length - 2 > 0 && (
              <Pill variant={PillVariant.Light}>
                <Tooltip
                  text={`+${categories.length - 2}`}
                  toolTipText={categories
                    .slice(3)
                    .map((category) => category.split(':')[0])
                    .join(', ')}
                />
              </Pill>
            )}
          </View>
        </Row>
      ) : null}
      {jobRoles ? (
        <Row style={styles.jobRolesContainer}>
          <Text style={[styles.subHeading, { marginBottom: 6 }]}>Job role:</Text>

          <View style={styles.pillContainer}>
            {jobRoles.slice(0, 2).map((jobRole) => {
              if (jobRole) {
                return <Pill key={jobRole} variant={PillVariant.Light} withMarginRight text={jobRole} />;
              }
            })}
            {jobRoles.length - 2 > 0 ? (
              <TooltipWithChild toolTipText={jobRoles.slice(2).join(', ')}>
                <Pill variant={PillVariant.Light} text={`+${jobRoles.length - 2}`} />
              </TooltipWithChild>
            ) : null}
          </View>
        </Row>
      ) : null}
    </View>
  );
};

interface InfoRowProps {
  averageRating?: number | null;
  reviewCount?: number;
  viewCount?: number | null;
  commentCount?: number;
  shareCount?: number | null;
  jobRoles?: string[] | null;
  isContent?: boolean;
  id?: string;
  contentType?: ContentType;
  categories?: string[] | null;
  hideCommentCount?: boolean;
}

const InfoRow = ({
  id,
  averageRating,
  reviewCount,
  viewCount,
  commentCount,
  shareCount,
  jobRoles,
  isContent,
  contentType,
  hideCommentCount,
  categories,
}: InfoRowProps) => {
  const { pathname } = useLocation();

  const [isHovered, setIsHovered] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const handleCopyLink = () => {
    setShowToast(true);
    navigator.clipboard.writeText(window.location.href);
  };

  const webIconProps =
    Platform.OS === 'web'
      ? {
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false),
        }
      : {};
  return (
    <>
      {Platform.OS === 'web' && (
        <>
          {isContent && (
            <View style={styles.topRow} dataSet={{ media: ids.topRow }}>
              <Col>
                {reviewCount && averageRating ? (
                  <Row style={{ marginBottom: 4 }}>
                    <Image source={Star} style={styles.star} />
                    <Text style={styles.subduedText}>
                      {averageRating} ({reviewCount} {pluralize(reviewCount || 0, 'review')})
                    </Text>
                  </Row>
                ) : null}
                <Row>
                  <Text style={styles.subduedText}>
                    {`${viewCount} ${pluralize(viewCount || 0, 'view')}`}
                    {!hideCommentCount ? ` ${commentCount} ${pluralize(commentCount || 0, 'comment')}` : ``}
                  </Text>
                </Row>
              </Col>
              {showToast && <Toast text="Link copied!" active={showToast} onDismiss={() => setShowToast(false)} duration={4500} />}
            </View>
          )}
        </>
      )}
      <CategoriesJobRoleTags categories={categories} jobRoles={jobRoles} />
    </>
  );
};

export { InfoRow };
