import { Col } from '@components/general/col';
import { StarIcon } from '@components/general/icons/feed-icons';
import { LockIcon } from '@components/general/icons/training-icons/LockIcon';
import { Image } from '@components/general/image-from-storage';
import { INITIALS_CIRCLE_BACKGROUND_COLORS, InitialsCircle } from '@components/general/initials-circle';
import { Pill, PillVariant } from '@components/general/pill';
import { Row } from '@components/general/row';
import { SaveMenu } from '@components/general/save-menu';
import { TouchableOpacityLink } from '@components/general/touchable-opacity-link';
import { useUserContext } from '@context/UserContext';
import { DocumentAsset, Instructor, Speaker } from '@gql/generated/generated';
import { useGetFile } from '@hooks/useGetFileUrl';
import { textStyles } from '@styles/text';
import { sortArrayItemsToOrder } from '@utils/Array';
import { convertSecondsToDisplay } from '@utils/TIme';
import { getInitialsFromName, getUniqueCategories, pluralize } from '@utils/misc';
import { PageVariant } from '@utils/models';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { ImageBackground, ImageStyle, Platform, Text, TouchableOpacity, View } from 'react-native';

import { ids, styles } from './style';
import { JobRolesWithTooltip } from '../../job-roles-with-tooltip';
import { Tooltip, TooltipWithChild } from '../../tooltip';

interface Props {
  onPress: () => void;
  coverImage?: DocumentAsset | null;
  id: string;
  title?: string | null;
  categories: string[];
  date?: number | null;
  speakers?: Speaker[] | Instructor[] | null;
  description?: string | null;
  location?: string | null;
  jobRoles?: string[] | null;
  userType?: string[] | null;
  variant: PageVariant;
  lessons?: number;
  shares?: number | null;
  views?: number | null;
  isCurrentUserEnrolled?: boolean;
  averageStars?: number | null;
  shareCount?: number | null;
  commentCount?: number;
  durationInSeconds?: number | null;
  publishedAt?: number | null;
  type?: string | null;
}

const getIndexPageCardLinkPath = (id: string, variant: PageVariant) => {
  switch (variant) {
    case PageVariant.content:
      return `/content/${id}`;
    case PageVariant.event:
      return `/events/${id}`;
    case PageVariant.training:
      return `/training/${id}`;
    default:
      return '/';
  }
};

const SUBSCRIPTION_TYPES = ['Free', 'Plus', 'Club'];

export const IndexPageCard = ({
  onPress,
  id,
  title,
  categories,
  date,
  publishedAt,
  location,
  coverImage,
  jobRoles,
  description,
  speakers,
  userType: userTypeArray,
  variant,
  lessons,
  shares,
  views,
  isCurrentUserEnrolled,
  averageStars,
  commentCount,
  shareCount,
  durationInSeconds,
  type,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const userType = userTypeArray?.join().toLowerCase() === 'all' ? SUBSCRIPTION_TYPES : userTypeArray;
  const userTypeText = userType && sortArrayItemsToOrder(userType, SUBSCRIPTION_TYPES).join(' & ');

  const { checkUserHasAccess } = useUserContext();
  const userHasAccess = checkUserHasAccess(userType as string[]);

  const hasSpeakers = speakers && speakers?.length > 0;
  const hasMultipleSpeakers = hasSpeakers && speakers?.length > 1;
  const firstSpeaker = hasSpeakers ? speakers?.[0] : null;
  const amountOfSpeakers = speakers?.length ?? 0;
  const speakerText = hasSpeakers ? `${firstSpeaker?.name} ${amountOfSpeakers > 1 ? `+${amountOfSpeakers - 1}` : ''}` : '';
  const [_, ...restSpeakers] = speakers || [];
  const speakerNames = hasSpeakers ? restSpeakers.map((s) => s?.name).join(', ') : '';

  const { fileUrl: coverImageUrl } = useGetFile(coverImage?.storagePath);

  const formattedCategories = getUniqueCategories(categories);

  return (
    <>
      <View style={[styles.container, isHovered && styles.hoverShadow]} dataSet={{ media: ids.container }}>
        <TouchableOpacityLink
          onPress={onPress}
          // @ts-ignore
          style={styles.touchableArea}
          // @ts-ignore
          onMouseEnter={() => setIsHovered(true)}
          // @ts-ignore
          onMouseLeave={() => setIsHovered(false)}
          href={getIndexPageCardLinkPath(id, variant)}
        >
          <View style={{ flex: 1 }}>
            <ImageBackground
              source={{
                uri: coverImageUrl,
              }}
              resizeMode="cover"
              // @ts-ignore
              style={styles.image}
              imageStyle={styles.roundedImage as ImageStyle}
            >
              {/* @ts-ignore */}
              <View style={styles.infoSectionTop}>
                <View style={[styles.flexRow, { alignItems: 'flex-start' }]}>
                  {formattedCategories ? (
                    <View style={{ marginBottom: 4, marginRight: 4 }}>
                      <Pill text={formattedCategories[0]} />
                    </View>
                  ) : null}
                  {formattedCategories.length > 1 && (
                    <TooltipWithChild toolTipText={formattedCategories.slice(1).join(', ')}>
                      <Pill variant={PillVariant.Light} text={`+ ${formattedCategories.length - 1}`} withMarginRight />
                    </TooltipWithChild>
                  )}
                </View>
                {/* TODO: High fidelity designs no longer have a 'registered' Pill,
                but do show the status of the event (updated/cancelled) */}
                {/* {status ? (
                <View>
                  <Pill text={status} variant={PillVariant.Gold} />
                </View>
              ) : null} */}
                {variant === PageVariant.training && isCurrentUserEnrolled ? (
                  <View>
                    <Pill text="Enrolled" variant={PillVariant.Green} />
                  </View>
                ) : null}
              </View>
              {isHovered ? (
                // @ts-ignore
                <View style={styles.viewContainer}>
                  <Text>View</Text>
                </View>
              ) : null}
            </ImageBackground>
            {/* @ts-ignore */}
            <View style={styles.infoCard}>
              <Col
                style={{
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <View style={[styles.flexRow, styles.topRow]}>
                    {variant !== PageVariant.content ? (
                      // @ts-ignore
                      <View style={styles.innerRow}>
                        {hasSpeakers && (
                          <>
                            <View
                              style={{
                                position: 'absolute',
                              }}
                            >
                              {firstSpeaker!.photo ? (
                                <Image
                                  path={firstSpeaker!.photo.storagePath}
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 20,
                                  }}
                                />
                              ) : (
                                <InitialsCircle
                                  initials={getInitialsFromName(firstSpeaker?.name || '')}
                                  backgroundColor={INITIALS_CIRCLE_BACKGROUND_COLORS[4]}
                                  size={24}
                                  fontSize={12}
                                />
                              )}
                            </View>
                            <Tooltip
                              text={speakerText}
                              toolTipText={speakerNames}
                              showTooltip={hasMultipleSpeakers as boolean}
                              textStyles={{
                                ...styles.speakerText,
                                ...textStyles.subduedTextSmall,
                                ...{ marginLeft: 30 },
                              }}
                            />
                          </>
                        )}
                      </View>
                    ) : null}
                    {/* @ts-ignore */}
                    {variant === PageVariant.content ? <Text style={styles.contentDate}>{dayjs(publishedAt).format('D MMMM YY')}</Text> : null}
                    <TouchableOpacity activeOpacity={1}>
                      <SaveMenu
                        activator="Dots"
                        id={id}
                        type={variant === PageVariant.content ? 'Content' : variant === PageVariant.event ? 'Event' : 'Training'}
                        categories={categories}
                        coverImage={coverImageUrl}
                        title={title}
                        additionalDetail={[
                          ...(views ? [`${views} ${pluralize(views, 'view')}`] : []),
                          ...(commentCount ? [`${commentCount} ${pluralize(commentCount, 'comment')}`] : []),
                        ]}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.flexRow, styles.titleBox]}>
                    {/* @ts-ignore */}
                    <Text style={styles.title} numberOfLines={1}>
                      {!userHasAccess ? <LockIcon style={{ marginRight: 10 }} /> : null}
                      {title}
                    </Text>
                  </View>
                  {/* @ts-ignore */}
                  <Text style={styles.description} numberOfLines={3}>
                    {description && parse(description)}
                  </Text>
                </View>
                <View style={{ display: 'flex' }}>
                  {variant === PageVariant.event ? (
                    <>
                      {/* @ts-ignore */}
                      <Text style={styles.dateAndLocationText}>Date & Location:</Text>
                      {/* @ts-ignore */}
                      <Text style={styles.date}>{date ? dayjs(new Date(date)).format('DD MMMM YYYY, h:mma') : ''}</Text>
                    </>
                  ) : null}
                  {variant === PageVariant.training ? (
                    // @ts-ignore
                    <Text style={styles.date}>
                      {convertSecondsToDisplay(durationInSeconds || 0)}
                      {' • '}
                      {lessons! > 1 ? `${lessons} lessons` : `${lessons} lesson`}
                    </Text>
                  ) : null}
                  <Text style={[styles.subduedText, { marginBottom: 20 }]}>{[type, location].filter((component) => component).join(' • ')}</Text>
                  {Platform.OS === 'web' && jobRoles?.length ? <JobRolesWithTooltip jobRoles={jobRoles} noMargin /> : null}
                </View>
              </Col>
            </View>
          </View>

          {/* @ts-ignore */}
          <View style={styles.bottomContainer}>
            {/* @ts-ignore */}
            <Row style={styles.centeredRow}>
              {variant === PageVariant.event ? (
                // @ts-ignore
                <Text style={styles.statsText}>
                  {views || '0'} {pluralize(views || 0, 'view')}
                </Text>
              ) : (
                <Row>
                  <StarIcon />
                  {/* @ts-ignore */}
                  <Text style={styles.reviewsText}>
                    {averageStars || 0} ({commentCount ? commentCount : 0} {pluralize(commentCount || 0, 'review')})
                  </Text>
                </Row>
              )}
              {userType?.length ? <Pill variant={PillVariant.LightBlue} text={userTypeText} /> : null}
            </Row>
          </View>
        </TouchableOpacityLink>
      </View>
    </>
  );
};
