import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { TabTitle } from '@components/general/tab-title';
import { PulseContentItem } from '@components/roadmap/pulse-content-item';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { DocumentAsset, RoadmapPulseAssignmentAssignee, RoadmapPulseScoreAssnSubCategoryLevelContentType } from '@gql/generated/generated';
import { useGetObjectsAlgolia } from '@hooks/useGetObjectsAlgolia';
import { ContentAlgolia } from '@pages/back-office/roadmap-back-office/score-associations-edit/ScoreAssociationsEdit';
import { Banner, Select } from '@shopify/polaris';
import { truncate } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './style';
import { getQuestionScore, getSubCategoryRecommendation } from '../pulse-result-plan/utils';

const getCompletedAssigneeSubtitle = (completedAssignee: RoadmapPulseAssignmentAssignee) => {
  const { firstName, lastName, completedAt } = completedAssignee;
  return `Completed by ${firstName} ${lastName} on ${new Date(completedAt as number).toDateString()}`;
};

export function PulseRecommendations() {
  const [activeTab, setActiveTab] = useState<'content' | 'training'>('content');
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('roadmap pulse context has not been provided');
  const {
    scoreAssns,
    isLoading: isRoadmapPulseLoading,
    selectedPulse,
    isSelectedPulseLatest,
    upsertPulseContentAssignment,
    isRefetching,
  } = roadmapPulseContext;
  const { roadmapPulseId, sectionId, subCategoryId } = useParams();
  const navigate = useNavigate();

  const subCategories = useMemo(() => {
    if (sectionId && !isRoadmapPulseLoading && scoreAssns.length && subCategoryId && isSelectedPulseLatest) {
      const category = scoreAssns.find((s) => s.name === sectionId);
      return category ? category.subCategories : [];
    }
    return [];
  }, [sectionId, isRoadmapPulseLoading, scoreAssns, subCategoryId, isSelectedPulseLatest]);

  const subcategoryResults = useMemo(() => {
    if (selectedPulse && !isRoadmapPulseLoading && sectionId && subCategoryId) {
      const categoryResults = selectedPulse.results?.find((c) => c.category === sectionId);
      const subcategoriesResults = categoryResults?.subcategoriesResults ?? [];
      const subcategoryResults = subcategoriesResults.find((s) => s?.subcategory === subCategoryId);
      return subcategoryResults || null;
    }
    return null;
  }, [selectedPulse, isRoadmapPulseLoading, sectionId, subCategoryId]);

  const subcategoryResultsHasAssignments = useMemo(() => {
    if (subcategoryResults) {
      return subcategoryResults.levelRecommendedContent?.length || subcategoryResults?.levelRecommendedTrainings?.length;
    }
    return null;
  }, [subcategoryResults]);

  const subcategoryResultsAssignmentsProgress = useMemo(() => {
    if (subcategoryResults && subcategoryResultsHasAssignments) {
      // if there are any assignments then return a number (e.g. 0 if none are completed), otherwise return null
      return subcategoryResults.levelAssignmentProgress?.totalLevelAssignmentsProgress ?? null;
    }
    return null;
  }, [subcategoryResults, subcategoryResultsHasAssignments]);

  const recommendations = useMemo(() => {
    const defaultReturn = { content: [], trainings: [] };
    if (subCategories.length && selectedPulse && sectionId && subCategoryId && isSelectedPulseLatest) {
      const subCategory = subCategories.find((s) => s.name === subCategoryId);
      if (!subCategory) return defaultReturn;
      // Get the section and question from the category
      const section = selectedPulse.sections.find((s) => s.name === sectionId);
      if (!section) return defaultReturn;
      const question = section.questions.find((q) => q.subcategory === subCategoryId);
      if (!question) return defaultReturn;
      const score = getQuestionScore(question);
      if (!score) return defaultReturn;
      const recommendations = getSubCategoryRecommendation(subCategory, score);
      return recommendations;
    }
    return defaultReturn;
  }, [subCategories, selectedPulse, sectionId, subCategoryId, isSelectedPulseLatest]);

  const [contentRecommendations, isContentRecommendationsLoading] = useGetObjectsAlgolia(
    recommendations.content.map((id) => ({
      id,
      indexName: 'content',
    }))
  );

  const [trainingRecommendations, isTrainingRecommendationsLoading] = useGetObjectsAlgolia(
    recommendations.trainings.map((id) => ({
      id,
      indexName: 'trainings',
    }))
  );

  const isLoading = useMemo(
    () => isTrainingRecommendationsLoading || isContentRecommendationsLoading || isRoadmapPulseLoading,
    [isTrainingRecommendationsLoading, isContentRecommendationsLoading, isRoadmapPulseLoading]
  );

  const subCategoryOptions = useMemo(() => {
    if (subCategories.length) {
      return subCategories.map((s) => {
        return {
          label: s.name!,
          value: s.name!,
        };
      });
    }
    return [];
  }, [subCategories]);

  const onCompleteAssignment = useCallback(
    async ({ contentType, contentID }: { contentType: RoadmapPulseScoreAssnSubCategoryLevelContentType; contentID: string }) => {
      await upsertPulseContentAssignment({
        categoryName: sectionId as string,
        subcategoryName: subCategoryId as string,
        scoreLevel: subcategoryResults?.levelAnswerScore as number, // NB this is brittle because it requires we have only one question per category/subcategory
        contentType,
        contentID,
      });
    },
    [sectionId, subCategoryId, subcategoryResults?.levelAnswerScore, upsertPulseContentAssignment]
  );

  function goToSection() {
    navigate(`/roadmap/pulse/${roadmapPulseId}/result/${sectionId}`);
  }

  function goToSubCategory(subCategoryId: string) {
    navigate(`/roadmap/pulse/${roadmapPulseId}/result/${sectionId}/${subCategoryId}`);
  }

  if (!isRefetching && isLoading) {
    return <ActivityIndicatorTRN isFullscreen />;
  }

  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox}>
        <TouchableOpacity onPress={goToSection}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          <View style={{ width: 'fit-content' }}>
            <Select label="" onChange={goToSubCategory} value={subCategoryId} options={subCategoryOptions} />
          </View>
          {subcategoryResultsAssignmentsProgress !== null && (
            <View style={styles.infoWrap}>
              <Text style={styles.infoPercentage}>{subcategoryResultsAssignmentsProgress ?? 0}%</Text>
              <Text style={styles.infoComplete}>Complete</Text>
            </View>
          )}
        </View>
        {!isSelectedPulseLatest && (
          <View style={{ marginTop: 16 }}>
            <Banner>
              <Text>You can only view recommended content from the latest pulse and only see previously completed content.</Text>
            </Banner>
          </View>
        )}
        <View style={styles.tabHead}>
          <View style={styles.tabInner}>
            <TabTitle title="Content" active={activeTab === 'content'} onPress={() => setActiveTab('content')} />
            <TabTitle title="Training" active={activeTab === 'training'} onPress={() => setActiveTab('training')} />
          </View>
        </View>
        {activeTab === 'content' &&
          (contentRecommendations as unknown as ContentAlgolia[]).map((r, i) => {
            const completedContentAssignment = subcategoryResults?.levelAssignmentProgress?.contentLevelAssignmentsCompleted?.find(
              (ass) => ass?.contentID === r.id
            );
            const completedAssignee = completedContentAssignment?.assignee;
            const subtitle = completedAssignee ? getCompletedAssigneeSubtitle(completedAssignee) : '';
            return (
              <PulseContentItem
                id={r.id}
                key={`${activeTab}-${r.id}-${i}`}
                title={r.title}
                description={truncate(r.description, { length: 60 })}
                type={RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT}
                subtitle={subtitle}
                isComplete={!!completedContentAssignment}
                completedAssignment={completedContentAssignment || undefined}
                image={r.coverImage as DocumentAsset}
                onPressComplete={onCompleteAssignment}
              />
            );
          })}
        {activeTab === 'training' &&
          (trainingRecommendations as unknown as ContentAlgolia[]).map((r, i) => {
            const completedTrainingAssignment = subcategoryResults?.levelAssignmentProgress?.trainingLevelAssignmentsCompleted?.find(
              (ass) => ass?.contentID === r.id
            );
            const completedAssignee = completedTrainingAssignment?.assignee;
            const subtitle = completedAssignee ? getCompletedAssigneeSubtitle(completedAssignee) : '';
            return (
              <PulseContentItem
                id={r.id}
                key={`${activeTab}-${r.id}-${i}`}
                title={r.title}
                description={truncate(r.description, { length: 60 })}
                type={RoadmapPulseScoreAssnSubCategoryLevelContentType.TRAININGS}
                subtitle={subtitle}
                isComplete={!!completedTrainingAssignment}
                completedAssignment={completedTrainingAssignment || undefined}
                image={r.coverImage as DocumentAsset}
                onPressComplete={onCompleteAssignment}
              />
            );
          })}
      </ContentBox>
    </Wrapper>
  );
}
