import { StarIcon } from '@components/general/icons/feed-icons';
import { CompleteIcon } from '@components/general/icons/training-icons';
import { Image } from '@components/general/image-from-storage';
import { InfoRow } from '@components/general/info-row';
import { Instructor, UserTrainingProgress } from '@gql/generated/generated';
import { textStyles } from '@styles/text';
import { convertSecondsToDisplay } from '@utils/TIme';
import { getInitials, pluralize } from '@utils/misc';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Text, View } from 'react-native';

import { ids, styles } from './style';

const TrainingDetailHead = ({
  instructors,
  averageStars,
  numLessons,
  userProgress,
  title,
  shortDescription,
  durationInSeconds,
  publishedAt,
  numEnrolledUsers,
  commentCount,
  shareCount,
  jobRoles,
}: {
  instructors?: Instructor[] | null;
  averageStars?: number | null;
  numLessons?: number | null;
  userProgress?: UserTrainingProgress | null;
  title?: string | null;
  shortDescription?: string | null;
  durationInSeconds?: number | null;
  publishedAt?: number | null;
  numEnrolledUsers?: number | null;
  commentCount?: number | null;
  shareCount?: number | null;
  jobRoles?: string[] | null;
}) => {
  const photo = instructors?.length ? instructors[0].photo : null;

  const progressBar = useMemo(() => {
    if (!userProgress) return null;
    if (userProgress.isCompleted) {
      return (
        <View style={styles.progressContainer}>
          <CompleteIcon />
          <Text style={textStyles.topic}>100% complete</Text>
        </View>
      );
    }
    return (
      <View style={styles.progressContainer}>
        <Text style={textStyles.topic}>{userProgress.trainingCompletionPercentage}% complete</Text>
        <View style={styles.progressBarOuter}>
          <View
            style={{
              ...styles.progressBarInner,
              width: `${userProgress.trainingCompletionPercentage}%`,
            }}
          />
        </View>
      </View>
    );
  }, [userProgress]);

  return (
    <View style={styles.wrap}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.description}>{shortDescription}</Text>
      <View style={styles.instructorNameWrap}>
        {photo?.storagePath ? (
          <Image path={photo.storagePath} style={styles.instructorInitialsWrap} />
        ) : (
          <View style={styles.instructorInitialsWrap}>
            <Text style={styles.instructorInitialsText}>{instructors?.length && getInitials(instructors[0].name)}</Text>
          </View>
        )}
        <Text style={styles.instructorName}>{instructors?.length && instructors[0].name}</Text>
      </View>
      <View style={styles.hoursWrap} dataSet={{ media: ids.hoursWrap }}>
        <Text style={styles.totalHoursText}>
          {convertSecondsToDisplay(durationInSeconds || 0)} total • {numLessons} {pluralize(numLessons ?? 0, 'lesson')}
        </Text>
        {publishedAt ? (
          <Text style={styles.dateCreated} dataSet={{ media: ids.dateCreated }}>
            Created on {dayjs(new Date(publishedAt)).format('D MMM YY')}
          </Text>
        ) : null}
        <View style={styles.enrolledWrap} dataSet={{ media: ids.enrolledWrap }}>
          <Text style={styles.enrolledText}>
            {numEnrolledUsers ?? 0}
            {pluralize(numEnrolledUsers ?? 0, ' student')} enrolled
          </Text>
        </View>
      </View>
      {progressBar}
      <View style={styles.reviewsWrap}>
        <StarIcon />
        <Text style={styles.reviewsText}>
          {averageStars} ({commentCount} {pluralize(commentCount ?? 0, 'review')})
        </Text>
      </View>
      {jobRoles?.length ? (
        <View style={styles.jobRoleWrap}>
          <InfoRow jobRoles={jobRoles} />
        </View>
      ) : null}
    </View>
  );
};

export { TrainingDetailHead };
