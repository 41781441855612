import { ButtonGeneral, ButtonType, ButtonVariant } from '@components/general/button-general';
import { Image } from '@components/general/image-from-storage';
import { Row } from '@components/general/row';
import { APP_URL } from '@env';
import { DocumentAsset, RoadmapPulseAssignment, RoadmapPulseScoreAssnSubCategoryLevelContentType } from '@gql/generated/generated';
import React, { useCallback, useState } from 'react';
import { Linking, Text, View } from 'react-native';

import { styles } from './style';
import { TickIcon } from '../../general/icons';

interface Props {
  image: DocumentAsset;
  title: string;
  description: string;
  type: RoadmapPulseScoreAssnSubCategoryLevelContentType;
  id: string;
  subtitle: React.ReactNode;
  isComplete: boolean;
  completedAssignment?: RoadmapPulseAssignment;
  onPressComplete?: (input: { contentType: RoadmapPulseScoreAssnSubCategoryLevelContentType; contentID: string }) => Promise<void>;
  isBorderDisabled?: boolean;
}

const PulseContentItem = ({ image, title, description, type, id, subtitle, isComplete, isBorderDisabled, onPressComplete }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePressComplete = useCallback(async () => {
    try {
      setIsLoading(true);
      if (onPressComplete) {
        await onPressComplete({ contentType: type, contentID: id });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [id, onPressComplete, type]);

  return (
    <View style={[styles.wrap, isBorderDisabled && styles.borderDisabled]}>
      <View style={styles.leftContent}>
        <Image path={image?.storagePath} style={styles.image} />
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.description}>{description}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
        </View>
      </View>
      <View style={styles.rightContent}>
        <View style={styles.buttonWrap}>
          <ButtonGeneral
            label="Open Link"
            type={ButtonType.user}
            variant={ButtonVariant.Secondary}
            onPress={() => Linking.openURL(`${APP_URL}/${type}/${id}`)}
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral
            type={ButtonType.user}
            isLoading={isLoading}
            onPress={handlePressComplete}
            style={isComplete ? { backgroundColor: '#33A5A1', borderColor: '#33A5A1' } : null}
            variant={ButtonVariant.Primary}
          >
            {isComplete ? (
              <Row>
                <TickIcon />
                <Text style={styles.buttonText}>Complete</Text>
              </Row>
            ) : (
              <Text style={styles.buttonText}>Mark as complete</Text>
            )}
          </ButtonGeneral>
        </View>
      </View>
    </View>
  );
};

export { PulseContentItem };
